.team-container {
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
}

.date {
  font-family: Apax-bold;
}
