.styled-table {
  border-collapse: collapse;
  margin: 20px 10px;
  width: -webkit-fill-available;
  max-width: 510px;
  font-size: 0.9em;
  font-family: Apax-regular;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: rgb(216, 216, 216);
  color: rgb(66, 66, 66);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: bolder;
  text-align: center;
}
.styled-table tr {
  cursor: pointer;
}
th.table-standings-header:first-child {
  text-align: left;
}
th.table-details-header:first-child {
  min-width: 100px;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.styled-table tbody tr:hover {
  background-color: rgb(247, 247, 247);
}

.styled-table .table-arrow {
  position: absolute;
}
