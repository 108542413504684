.nav-container .nav-logo {
  height: 4em;
  width: 4em;
  margin: 2em 1em 1em 4em;
  color: white;
  text-align: center;
  text-decoration: none;
}

.nav-left > a {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  width: 50px;
}

p.nav-logo-text {
  font-family: Apax-regular;
  font-size: 30px;
  color: #fff;
  margin-top: 5px;
  margin-left: 15px;
  margin-bottom: 12px;
}

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(22, 22, 26);
}

.nav-item-wrapper {
  display: flex;
  flex-direction: row;
}

.navbar ul {
  list-style-type: none;
}
.nav-item {
  margin: 2em;
}

.nav-link {
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 150%;
  font-family: Apax-medium;
}
