.timer-container {
  display: flex;
  justify-content: center;
  padding-top: 2em;
}

.player-container {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  flex-direction: column;
}

.teams-container {
  display: flex;
  justify-content: center;
  padding-top: 1em;
  flex-direction: row;
}
