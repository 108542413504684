.team-box {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  border: 2px solid black;
  padding: 10px 15px 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  width: 325px;
  height: 230px;
}

.team-stats {
  width: -webkit-fill-available;
  min-width: 325px;
}
.team-stats th {
  padding-top: 2px;
  padding-left: 15px;
  font-family: apax-regular;
  text-align: left;
  font-weight: 400;
}

.team-stats td {
  text-align: right;
}

.team-stats .total-goals th {
  padding-top: 15px;
}
.team-stats .total-goals td {
  padding-top: 15px;
}
.team-stats th > img {
  width: 20px;
}

.team-stats thead th {
  font-weight: bold;
  padding-bottom: 8px;
}
.team-stats thead th:last-child {
  text-align: right;
}

.header {
  justify-content: space-around;
}
.color-dot {
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 4px;
  margin-left: -12px;
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
}
